.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%
}

.toast-top-left {
  top: 12px;
  left: 12px
}

.toast-top-right {
  top: 12px;
  right: 12px
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px
}

.toast-title {
  font-weight: 700
}

.toast-message {
  word-wrap: break-word
}

.toast-message a, .toast-message label {
  color: #fff
}

.toast-message a:hover {
  color: #ccc;
  text-decoration: none
}

.toast-close-button {
  position: relative;
  right: -.3em;
  top: -.3em;
  float: right;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  text-shadow: 0 1px 0 #fff
}

.toast-close-button:focus, .toast-close-button:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: .4
}

button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999
}

.toast-container * {
  box-sizing: border-box
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999;
  color: #fff
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000;
  opacity: 1;
  cursor: pointer
}

.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E")
}

.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E")
}

.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E")
}

.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E")
}

.toast-container.toast-bottom-center .ngx-toastr, .toast-container.toast-top-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto
}

.toast-container.toast-bottom-full-width .ngx-toastr, .toast-container.toast-top-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto
}

.toast-success {
  background-color: #51a351
}

.toast-error {
  background-color: #bd362f
}

.toast-info {
  background-color: #2f96b4
}

.toast-warning {
  background-color: #f89406
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000;
  opacity: .4
}

@media (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em
  }
  .toast-container .toast-close-button {
    right: -.2em;
    top: -.2em
  }
}

@media (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em
  }
  .toast-container .toast-close-button {
    right: -.2em;
    top: -.2em
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em
  }
}

#toast-container > div {
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  max-width: none;
  border-radius: .286rem
}

#toast-container > div, #toast-container > div:hover {
  box-shadow: 0 2px 20px 0 rgba(34, 41, 47, .08)
}

#toast-container > .toast {
  background-image: none !important
}

.toast {
  background-color: #fff;
  color: #6e6b7b !important
}

.toast .toast-close-button {
  color: #6e6b7b;
  font-size: 1.75rem;
  font-weight: 400;
  top: 0;
  right: 0;
  text-shadow: none
}

.toast .toast-close-button:focus {
  outline: none
}

.toast .toast-title {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.75
}

.toast .toast-progress {
  opacity: 1
}

.toast:before {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  color: #fff;
  left: 1rem
}

.toast:after, .toast:before {
  content: "";
  position: absolute
}

.toast:after {
  background-size: 1rem;
  background-repeat: no-repeat;
  left: 1.38rem;
  top: 1.44rem;
  width: 1rem;
  height: 1rem
}

.toast-success .toast-title {
  color: #28c76f
}

.toast-success .toast-progress, .toast-success:before {
  background-color: #28c76f
}

.toast-success:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E")
}

.toast-error .toast-title {
  color: #ea5455
}

.toast-error .toast-progress, .toast-error:before {
  background-color: #ea5455
}

.toast-error:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E")
}

.toast-info .toast-title {
  color: #00cfe8
}

.toast-info .toast-progress, .toast-info:before {
  background-color: #00cfe8
}

.toast-info:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-info'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='16' x2='12' y2='12'%3E%3C/line%3E%3Cline x1='12' y1='8' x2='12.01' y2='8'%3E%3C/line%3E%3C/svg%3E")
}

.toast-warning .toast-title {
  color: #ff9f43
}

.toast-warning .toast-progress, .toast-warning:before {
  background-color: #ff9f43
}

.toast-warning:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-alert-triangle'%3E%3Cpath d='M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z'%3E%3C/path%3E%3Cline x1='12' y1='9' x2='12' y2='13'%3E%3C/line%3E%3Cline x1='12' y1='17' x2='12.01' y2='17'%3E%3C/line%3E%3C/svg%3E")
}

.toast-top-left {
  top: 1.3rem;
  left: 2rem
}

.toast-top-right {
  top: 1.3rem;
  right: 2rem
}

.toast-top-center, .toast-top-full-width {
  top: 1.3rem
}

.toast-bottom-left {
  bottom: 1.3rem;
  left: 2rem
}

.toast-bottom-right {
  bottom: 1.3rem;
  right: 2rem
}

.toast-bottom-center, .toast-bottom-full-width {
  bottom: 1.3rem
}

@media (max-width: 480px) and (min-width: 241px) {
  #toast-container > .toast:after {
    top: .95rem
  }
  #toast-container > .toast .toast-close-button {
    top: 0;
    right: -1px
  }
  #toast-container:not(.toast-top-full-width):not(.toast-bottom-full-width) > div {
    width: 20rem
  }
  .toast-top-left {
    left: 0
  }
  #toast-container.toast-bottom-center, #toast-container.toast-top-center {
    right: 1rem
  }
  #toast-container.toast-bottom-center > div, #toast-container.toast-top-center > div {
    width: 20rem !important
  }
  .toast-bottom-full-width, .toast-top-full-width {
    right: 0;
    left: 0;
    width: 93%
  }
  .toast-bottom-full-width > div, .toast-top-full-width > div {
    width: 100%
  }
  .toast-bottom-left {
    left: .25rem;
    bottom: .75rem
  }
  .toast-bottom-right {
    right: 2.25rem;
    bottom: .75rem
  }
  .toast-bottom-center, .toast-bottom-full-width {
    bottom: .75rem
  }
  [data-textdirection=rtl] #toast-container > div {
    padding-right: 8px
  }
}

.dark-layout #toast-container > div, .dark-layout #toast-container > div:hover {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, .24)
}

.dark-layout #toast-container .toast {
  background-color: #283046;
  color: #b4b7bd !important
}

.dark-layout #toast-container .toast .toast-close-button {
  color: #b4b7bd
}

@media (max-width: 575.98px) {
  [data-textdirection=rtl] .toast .toast-close-button {
    top: .55rem !important;
    right: .5rem !important
  }
}

[data-textdirection=rtl] .toast-top-left {
  left: 1rem;
  right: auto
}

[data-textdirection=rtl] .toast-top-right {
  right: 3rem;
  left: auto
}

[data-textdirection=rtl] .toast-bottom-left {
  left: 1em;
  right: auto
}

[data-textdirection=rtl] .toast-bottom-right {
  right: 3rem;
  left: auto
}

[data-textdirection=rtl] .toast-bottom-full-width#toast-container > div, [data-textdirection=rtl] .toast-top-full-width#toast-container > div {
  left: 0;
  right: 0
}

.toast-close-button {
  line-height: 1
}

@media (max-width: 480px) and (min-width: 241px) {
  #toast-container > .toast:after {
    top: 1.44rem
  }
}
